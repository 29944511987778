import FileSaver from 'file-saver';
import XLSX from 'xlsx';
//   * 姓名 * 证件类型 * 证件号码	户籍所在国家	户籍所在省份	户籍所在市	户籍所在区 * 联系方式 * 采样日期 * 检测结果 * 报告日期	职业分类	职业子分类	阳性出院时间	备注

function exportData(data) {
  //console.log("funciton ", data);
  let table = document.createElement('table');
  table.setAttribute('id', 'table_temp');
  var header = [
    '乡镇（村）',
    '1针次',
    '2针次',
    '总登记量',

    '待接种2针'
  ];
  var tr = document.createElement('tr');
  var td = '';
  for (let j in header) {
    td = document.createElement('td');
    td.innerText = header[j];
    tr.appendChild(td);
  }
  table.appendChild(tr);
  var keys = [
    'subtown',
    'count1',
    'count2',
    'total',
    'rate'


  ];
  for (let i = 0; i < data.length; i++) {
    tr = document.createElement('tr');
    for (let k = 0; k < keys.length; k++) {
      td = document.createElement('td');
      if (keys[k] in data[i]) {
        td.innerText = data[i][keys[k]];
      }
      tr.appendChild(td);
    }
    table.appendChild(tr);
  }

  var wb;
  var xlsxParam = {
    raw: true
  };
  wb = XLSX.utils.table_to_book(table, xlsxParam);
  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array'
  });

  var now = new Date();
  var filename = "统计数据_" + now.getFullYear() + "_" + (now.getMonth() + 1) + "_" + now.getDate() + ".xlsx";
  try {
    console.log('save');
    FileSaver.saveAs(
      new Blob([wbout], {
        type: 'application/octet-stream;charset=utf-8'
      }),
      filename
    );
  } catch (e) {
    if (typeof console !== 'undefined') console.log(e, wbout);
  }
  return wbout;
}

export { exportData };
