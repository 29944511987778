<template>
<div class="main-conent main-conent-minheight">
  <el-row :gutter="24">
    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
      <el-card class="box-card" shadow="never">
        <div slot="header" @click="init">
          <span style="margin-right:4px">今日</span>
          <svg-icon icon-class="flash" style="font-size:0.9em" />
        </div>
        <div class="num">{{ today }}</div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
      <el-card class="box-card" shadow="never">
        <div slot="header" @click="init">
          <span style="margin-right:4px">近一周</span>
          <svg-icon icon-class="flash" style="font-size:0.9em" />
        </div>
        <div class="num">{{ week }}</div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
      <el-card class="box-card" shadow="never">
        <div slot="header" @click="init">
          <span style="margin-right:4px">本月</span>
          <svg-icon icon-class="flash" style="font-size:0.9em" />
        </div>
        <div class="num">{{ month }}</div>
      </el-card>
    </el-col>

    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
      <el-card class="box-card" shadow="never">
        <div slot="header" @click="init">
          <span style="margin-right:4px">累计</span>
          <svg-icon icon-class="flash" style="font-size:0.9em" />
        </div>
        <div class="num">{{ total }}</div>
      </el-card>
    </el-col>
  </el-row>


</div>
</template>

<script>
import {
  mapGetters
} from "vuex";

//import axios from 'axios';
import request from "@/service/lib/request";
import {
  exportData
} from "@/utlis/export_today";
export default {
  name: "Home",
  data() {
    return {
      chart: null,
      resizeHandler: null,
      radio1: "今日",
      datevalue1: "",
      today: 0,
      week: 0,
      total: 0,
      month: 0


    };
  },
  computed: {
    ...mapGetters(["sidebarOpened"])
  },
  watch: {
    sidebarOpened() {
    // this.resizeHandler();
    }
  },
  methods: {
    download() {
      exportData(this.group_country);
    },
    init() {
      
      request({
          url: "tj/",
          method: "get"
        })
        .then((res) => {
          this.$message.success('成功更新数据！');
          let data = res.data;
          this.today=data.today;
          this.week=data.week;
          this.total=data.total;
          this.month=data.month;
         console.log(
           "data===",data);
    
        }).catch((err) => {
          this.$message.error(err.message+'@@');
            
         
        });
    }


  },
 mounted() {
    //const _this = this;
   
    this.init();
    // _this.setChart();

  },
  created() {
   
  }
};
</script>

<style lang="scss">
.box-card {
  border: 0;
  margin-bottom: 24px;

  .el-card__header {
    padding-left: 12px;
    padding-right: 12px;
  }

  .el-card__body {
    padding: 12px;
  }

  .num {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .el-divider--horizontal {
    margin: 8px 0;
  }

  .bot {
    font-size: 14px;
  }

  .traffic-title {
    margin: 0;
    margin-bottom: 10px;
  }

  .traffic-li {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    margin-top: 10px;
  }

  .sp {
    display: flex;
    justify-content: flex-end;
    align-content: center;

  }

  .cp {
    display: flex;
    justify-content: flex-start;
    align-content: center;

  }

  .traffic-num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 8px;

    &.hot {
      background-color: rgba($color: #209e91, $alpha: 0.8);
      color: #ffffff;
    }
  }
}

.stitle {
  display: flex;
  justify-content: space-between;
}
</style>
